import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, CheckboxGroup, FlexboxGrid, Icon, IconButton, Input, InputGroup, Panel} from "rsuite";

MenuForm.propTypes = {
    index: PropTypes.number.isRequired,
    menu: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    removeRow: PropTypes.func,
    deleteMenu: PropTypes.func,
    disabled: PropTypes.bool,
};

function MenuForm(props) {
    const {index, menu, onChange, disabled, handleChangeLocation, locationValues, isEdit} = props;

    return (
        <Panel header={`Menü ${index +1}`} bordered className={'menu-panel'}>

            <FlexboxGrid justify={'flex-start'}>
                <FlexboxGrid.Item colspan={2} style={{paddingRight: 10}}>
                    <InputGroup className={'input-group'}>
                        <InputGroup.Addon> <Icon icon={'list-ol'}/> </InputGroup.Addon>
                        <Input type={'text'}
                               placeholder={'Menü Nr.'}
                               defaultValue={menu.menu_id}
                               disabled={disabled}
                               name={'menu_id'}
                               onChange={(value) => onChange(index, 'menu_id', value)}
                        />
                    </InputGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8} style={{paddingRight: 10}}>
                    <InputGroup className={'input-group'}>
                        <InputGroup.Addon> <Icon icon={'file-text'}/> </InputGroup.Addon>
                        <Input type={'text'}
                               placeholder={'Menü Deutsch'}
                               defaultValue={menu.menuName}
                               disabled={disabled}
                               name={'menuName'}
                               onChange={(value) => onChange(index, 'menuName', value)}
                        />
                    </InputGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6} style={{paddingRight: 10}}>
                    <InputGroup className={'input-group'} >
                        <InputGroup.Addon> <Icon icon={'file-text'}/> </InputGroup.Addon>
                        <Input type={'text'}
                               placeholder={'Menü Englisch'}
                               defaultValue={menu.menuNameEn}
                               disabled={disabled}
                               name={'menuNameEn'}
                               onChange={(value) => onChange(index, 'menuNameEn', value)}
                        />
                    </InputGroup>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8} style={{paddingRight: 10}}>
                    <FlexboxGrid justify={'space-between'}>
                        <FlexboxGrid.Item colspan={12}>
                            <InputGroup className={'input-group'}>
                                <InputGroup.Addon> <Icon icon={'eur'}/> </InputGroup.Addon>
                                <Input type={'text'}
                                       placeholder={'Preis'}
                                       disabled={disabled}
                                       defaultValue={menu.price}
                                       name={'price'}
                                       onChange={(value) => onChange(index, 'price', value)}
                                />
                            </InputGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>
                            <Checkbox value={'isSpecial'} disabled={disabled} checked={menu.isSpecial} onChange={(value, checked) => onChange(index, 'isSpecial', value)}>Spezial</Checkbox>
                            {props.deleteMenu ?
                                <IconButton icon={<Icon icon="trash" />} onClick={() => props.deleteMenu(menu.id)}/>
                                :
                                <IconButton icon={<Icon icon="trash" />} onClick={() => props.removeRow(index)}/>
                            }
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
                {isEdit &&
                <FlexboxGrid.Item colspan={8}>
                    <FlexboxGrid justify={'space-between'}>
                        <FlexboxGrid.Item>
                            <CheckboxGroup
                                name={'checkLocations'}
                                value={locationValues}
                                onChange={value => {handleChangeLocation(index, value)}}
                            >
                                <Checkbox value={'rsg_cham'}>Robert-Schumann-Gymnasium</Checkbox>
                                <Checkbox value={'jvfg_cham'}>Joseph-von-Fraunhofer-Gymnasium</Checkbox>
                                <Checkbox value={'kantine10'}>Kantine 10</Checkbox>
                                <Checkbox value={'crown'}>Kantine Crown</Checkbox>
                                <Checkbox value={'gms'}>GMS Roding</Checkbox>
                            </CheckboxGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
                }
                <FlexboxGrid.Item colspan={isEdit ? 16 : 24}>
                    <FlexboxGrid justify={'space-between'}>
                        <FlexboxGrid.Item>
                            <CheckboxGroup inline
                                           name="additions"
                                           className={'additionsChecker'}
                                           value={menu.additions}
                                           style={{marginLeft: 0}}
                                           onChange={(value) => onChange(index, 'additions', value)}
                            >
                                <Checkbox disabled={disabled} value={'1'}>1</Checkbox>
                                <Checkbox disabled={disabled} value={'2'}>2</Checkbox>
                                <Checkbox disabled={disabled} value={'3'}>3</Checkbox>
                                <Checkbox disabled={disabled} value={'4'}>4</Checkbox>
                                <Checkbox disabled={disabled} value={'5'}>5</Checkbox>
                                <Checkbox disabled={disabled} value={'6'}>6</Checkbox>
                                <Checkbox disabled={disabled} value={'7'}>7</Checkbox>
                                <Checkbox disabled={disabled} value={'8'}>8</Checkbox>
                                <Checkbox disabled={disabled} value={'9'}>9</Checkbox>
                                <Checkbox disabled={disabled} value={'10'}>10</Checkbox>
                                <Checkbox disabled={disabled} value={'11'}>11</Checkbox>
                                <Checkbox disabled={disabled} value={'12'}>12</Checkbox>
                                <Checkbox disabled={disabled} value={'13'}>13</Checkbox>
                                <br/>
                                <Checkbox disabled={disabled} value={'a'}>a</Checkbox>
                                <Checkbox disabled={disabled} value={'b'}>b</Checkbox>
                                <Checkbox disabled={disabled} value={'c'}>c</Checkbox>
                                <Checkbox disabled={disabled} value={'d'}>d</Checkbox>
                                <Checkbox disabled={disabled} value={'e'}>e</Checkbox>
                                <Checkbox disabled={disabled} value={'f'}>f</Checkbox>
                                <Checkbox disabled={disabled} value={'g'}>g</Checkbox>
                                <Checkbox disabled={disabled} value={'h'}>h</Checkbox>
                                <Checkbox disabled={disabled} value={'i'}>i</Checkbox>
                                <Checkbox disabled={disabled} value={'j'}>j</Checkbox>
                                <Checkbox disabled={disabled} value={'k'}>k</Checkbox>
                                <Checkbox disabled={disabled} value={'l'}>l</Checkbox>
                                <Checkbox disabled={disabled} value={'m'}>m</Checkbox>
                                <Checkbox disabled={disabled} value={'n'}>n</Checkbox>
                            </CheckboxGroup>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>

        </Panel>
    );
}

export default MenuForm;