import React, {useState} from 'react';
import {
    Alert,
    Button,
    DatePicker,
    Divider,
    FlexboxGrid,
    Icon,
    Panel,
    List,
    RadioGroup, Radio
} from "rsuite";
import {API_HOST, locale, locationNames} from "../../helper/constants";
import axios from "axios";
import dayjs from "dayjs";
import OrdersTable from "../../Components/Orders/OrdersTable";
import '../../styles/order-styles.less';


function Orders(props) {
    const [orderDay, setOrderDay] = useState();
    const [orders, setOrders] = useState([]);
    const [menuCount, setMenuCount] = useState({});
    const [printView, setPrintView] = useState(false);
    const [locationValues, setLocationValues] = useState({locationList: ''});
    const [orderUri, setOrderUri] = useState('');

    function handleChangeLocation(value) {
        setLocationValues({locationList: value});
        setOrderDay(null);
    }
    function calcMenus(menus) {
        let countObj = {}
        menus.forEach(menu => {
            if(menu.fullMenu === 1) {
                if (countObj.hasOwnProperty(menu.menu+'-Komplett')) {
                    countObj[menu.menu+'-Komplett'] += 1;
                } else {
                    Object.assign(countObj, {[menu.menu+'-Komplett']: 1})
                }
            } else {
                if (countObj.hasOwnProperty(menu.menu)) {
                    countObj[menu.menu] += 1
                } else {
                    Object.assign(countObj, {[menu.menu]: 1})
                }
            }
        })
        setMenuCount(countObj)
    }
    function loadOrders(date) {
        setOrderDay(date);
        setOrderUri(`/os-print?date=${dayjs(date).format('YYYY-MM-DD')}&location=${locationValues.locationList}`);
        let token = localStorage.getItem('sh-token');
        axios.get(`${API_HOST}/orders?date=${dayjs(date).format('YYYY-MM-DD')}&location=${locationValues.locationList}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (date !== null) {
                if (response.status < 300 && response.data.orders.length > 0) {
                    calcMenus(response.data.orders);
                    setOrders(response.data.orders);
                } else {
                    Alert.warning('Für diesen Tag sind keine Bestellungen vorhanden');
                }
            }
        }).catch(exp => {
            Alert.error('Leider ist ein Fehler aufgetretten: ' + exp)
        })
    };
    function cleanOrders() {
        setOrderDay('');
        setOrders([])
    };

    if(printView) {
        return (
            <div className={'printOnly'}>
                <div className={'printModal'}>
                    {locationValues.locationList &&
                    <p style={{marginBottom: 10}}>Bestellungen für {locationNames[locationValues.locationList]}</p>
                    }
                    <OrdersTable orders={orders} />
                    <List>
                        {Object.keys(menuCount).map((item, index) => (
                            <List.Item key={index} index={index}>{item}: <strong>{menuCount[item]}</strong></List.Item>
                        ))}
                    </List>
                </div>
                <Button className={'noPrint'} color={'green'} onClick={() => window.print()} style={{margin: 3, float: 'right'}}><Icon
                    icon={'print'}/>
                    &nbsp;Drucken</Button>
                <Button className={'noPrint'} appearance="primary" ripple onClick={() => setPrintView(!printView)} style={{margin: 3, float: 'left'}}>Zurück</Button>
            </div>
        )
    } else {
    return (
        <div className={'component-root'}>
            <h1>Bestellungen Einsehen</h1>
            <Panel header={(<span><Icon icon={'th-list'}/> Tag für Bestellungen auswählen</span>)} bordered>
                <FlexboxGrid justify={'space-between'}>
                    <FlexboxGrid.Item>
                        <RadioGroup
                            name={'locationList'}
                            inline
                            value={locationValues.locationList}
                            onChange={value => {handleChangeLocation(value)}}
                        >
                            <Radio value={'rsg_cham'}>Robert-Schuman-Gymnasium</Radio>
                            <Radio value={'jvfg_cham'}>Joseph-von-Fraunhofer-Gymnasium</Radio>
                            <Radio value={'kantine10'}>Kantine 10</Radio>
                            <Radio value={'crown'}>Kantine Crown</Radio>
                            <Radio value={'gms'}>GMS Roding</Radio>
                        </RadioGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={20}>
                        <DatePicker
                            size="lg"
                            placeholder="Tag wählen"
                            format={'DD.MM.YYYY'}
                            isoWeek
                            showWeekNumbers
                            oneTap
                            block
                            value={orderDay}
                            onChange={(date) => setOrderDay(date)}
                            onClean={() => cleanOrders()}
                            locale={locale}
                            className={'noPrint'}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                        <Button color={'green'} onClick={() => loadOrders(orderDay)} style={{margin: 3, float: 'right'}}>Bestellungen Laden</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <Divider />
                <div className={'printModal'}>
                    {locationValues.locationList &&
                        <p style={{marginBottom: 10}}>Bestellungen für {locationNames[locationValues.locationList]}</p>
                    }
                    <OrdersTable orders={orders} />
                <List>
                    {Object.keys(menuCount).map((item, index) => (
                        <List.Item key={index} index={index}>{item}: <strong>{menuCount[item]}</strong></List.Item>
                    ))}
                </List>
                </div>
                <Button className={'noPrint'} appearance="primary" style={{float: 'right'}} ripple onClick={() => window.open(orderUri)}><Icon
                    icon={'print'}/>
                    &nbsp;Druckversion laden
                </Button>
                <div style={{clear: 'both'}} />
            </Panel>
        </div>
    );
    }
}

export default Orders;