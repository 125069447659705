import React, {useState} from 'react';
import PropTypes from 'prop-types';
import 'rsuite/lib/styles/themes/dark/index.less';
import '../styles/login-styles.less';
import {Input, Button, FlexboxGrid, Panel} from "rsuite";


function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function handleText(value, type) {
        if (type === 'email') {
            setEmail(value);
        }
        if (type === 'password') {
            setPassword(value)
        }
    };

    function onLogin() {
        const loginData = {
            email: email,
            password: password
        };
        props.callback(loginData);
    }

    return (
        <div id={'login-container'}>
            <FlexboxGrid className={'login-flex'} align={'middle'} justify={'center'}>
                <FlexboxGrid.Item colspan={8}>
                    <Panel shaded bordered bodyFill className={'login-panel'}>
                        <h1>SH-Admin</h1>
                        <h2>Login</h2>
                        <Input type={'text'}
                               placeholder={'Benutzername'}
                               value={email}
                               onChange={(value) => handleText(value, 'email')}
                        />
                        <Input type={'password'}
                               placeholder={'Passwort'}
                               value={password}
                               onChange={(value) => handleText(value, 'password')}
                        />
                        <Button appearance="primary" ripple block loading={props.loading}
                                onClick={() => onLogin()}>Login</Button>
                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
}

Login.propTypes = {
    callback: PropTypes.func.isRequired,
};

export default Login;