export const API_HOST = 'https://api.s-h.bayern/api'
// export const API_HOST = 'http://localhost:8000/api'

export const locale={
        sunday: 'So',
        monday: 'Mo',
        tuesday: 'Di',
        wednesday: 'Mi',
        thursday: 'Do',
        friday: 'Fr',
        saturday: 'Sa',
        ok: 'OK',
        today: 'Heute',
        yesterday: 'Gestern',
        hours: 'Stunden',
        minutes: 'Minuten',
        seconds: 'Sekunden'
}

export const days = {
        sunday: 'Sonntag',
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',

}

export const locationNames = {
        rsg_cham: 'Robert-Schuman-Gymnasium',
        jvfg_cham: 'Joseph-von-Fraunhofer-Gymnasium',
        kantine10: 'Kantine 10',
        crown: 'Kantine Crown',
        gms: 'GMS Roding'
    }