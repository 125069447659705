import React, {useState} from 'react';
import {
    Alert,
    Button,
    DatePicker,
    DateRangePicker,
    Divider,
    FlexboxGrid,
    Icon,
    Panel,
    RadioGroup, Radio
} from "rsuite";
import {API_HOST, locale, locationNames} from "../helper/constants";
import axios from "axios";
import dayjs from "dayjs";
import PrintContainer from "./PrintContainer";


function PrintMenu(props) {
    const [startCw, setStartCw] = useState();
    const [endCw, setEndCw] = useState();
    const [menus, setMenus] = useState([]);
    const [printView, setPrintView] = useState(false)
    const [locationValues, setLocationValues] = useState({locationList: ''});
    const [menuUri, setMenuUri] = useState('')

    function handleChangeLocation(value) {
        setLocationValues({locationList: value});
    }

    function loadMenus(start, end) {

        const callStart = dayjs(start).week();
        const callEnd = dayjs(end).subtract(2, 'day').week();

        setMenuUri(`/os-print?location=${locationValues.locationList}&start=${callStart}&end=${callEnd}`);

        if (callStart > callEnd) {
            Alert.warning('Das Start Datum muss vor dem End Datum liegen!');
        } else {
            let token = localStorage.getItem('sh-token');
            axios.get(`${API_HOST}/print-menus?start=${callStart}&end=${callEnd}&location=${locationValues.locationList}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                if (response.status < 300) {
                    setMenus(response.data.menus);
                }
            }).catch(exp => {
                Alert.error('Leider ist ein Fehler aufgetretten: ' + exp)
            })
        }
    };
    function cleanOrders() {
        setStartCw(null);
        setEndCw(null);
        setLocationValues({locationList: ''})
        setMenus([])
    };
    return (
        <div className={'component-root'}>
            <h1>Menüs Drucken</h1>
            <Panel header={(<span><Icon icon={'th-list'}/> Zeitraum für Menüs zum Drucken auswählen</span>)} bordered>
                <FlexboxGrid className={'noPrint'} justify={'space-between'}>
                    <FlexboxGrid.Item colspan={24}>
                        <RadioGroup
                            name={'locationList'}
                            inline
                            value={locationValues.locationList}
                            onChange={value => {handleChangeLocation(value)}}
                        >
                            <Radio value={'rsg_cham'}>Robert-Schuman-Gymnasium</Radio>
                            <Radio value={'jvfg_cham'}>Joseph-von-Fraunhofer-Gymnasium</Radio>
                            <Radio value={'kantine10'}>Kantine 10</Radio>
                            <Radio value={'crown'}>Kantine Crown</Radio>
                            <Radio value={'gms'}>GMS Roding</Radio>
                        </RadioGroup>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={9}>
                        <DateRangePicker
                            hoverRange={'week'}
                            isoWeek ranges={[]}
                            showOneCalendar
                            showWeekNumbers
                            format={'DD.MM.YYYY'}
                            locale={locale}
                            className={'noPrint'}
                            oneTap
                            onClean={() => cleanOrders()}
                            onChange={(date) => {setStartCw(date[0]); setEndCw(date[1])}}
                        />
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={4}>
                        <Button color={'green'} onClick={() => loadMenus(startCw, endCw)} style={{margin: 3, float: 'right'}}>Menüs Laden</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <Divider />
                <div className={'printModal'}>
                    {locationValues.locationList &&
                    <p style={{marginBottom: 10}}>Menüs für {locationNames[locationValues.locationList]}</p>
                    }
                    <PrintContainer menus={menus} />
                </div>
                <Button className={'noPrint'} appearance="primary" style={{float: 'right'}} ripple onClick={() => window.open(menuUri)}><Icon
                    icon={'print'}/>
                    &nbsp;Druckversion laden
                </Button>
                <div style={{clear: 'both'}} />
            </Panel>
        </div>
    );
}

export default PrintMenu;