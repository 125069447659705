import React, {useState} from 'react';
import '../../styles/component.less';
import {
    Icon,
    Panel,
    DatePicker,
    FlexboxGrid,
    Checkbox,
    Divider,
    Button, Alert, CheckboxGroup, Loader
} from "rsuite";
import {API_HOST, locale} from "../../helper/constants";
import dayjs from "dayjs";
import WeekOfYear from 'dayjs/plugin/weekOfYear'
import MenuForm from "../../Components/AddMenu/MenuForm";
import axios from "axios";

dayjs.extend(WeekOfYear)

AddMenu.propTypes = {
    
};

const options = [
    'rsg_cham',
    'jvfg_cham',
    'kantine10',
    'crown',
    'gms'
]

function AddMenu(props) {
    const today = new Date();
    const [menuDay, setMenuDay] = useState(today);
    const [dayClosed, setDayClosed] = useState(false)
    const [menu, setMenu] = useState([
        {
            id: 1,
            menuName: '',
            menuNameEn: '',
            menu_id: 1,
            price: null,
            isSpecial: false,
            additions: []
        }
    ]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [locationValues, setLocationValues] = useState([]);
    const [loading, setLoading] = useState(false);

    function addRow() {
        let emptyMenu = {
            id: menu.length + 1,
            menuName: '',
            menu_id: menu.length + 1,
            menuNameEn: '',
            price: null,
            isSpecial: false,
            additions: []
        }
        menu.push(emptyMenu);
        setMenu([...menu]);
    }
    function removeRow(index) {
        let newMenu = menu;
        newMenu.forEach(item => item.menu_id > index ? item.menu_id -= 1 : item.menu_id)
        newMenu.splice(index, 1);
        setMenu([...newMenu]);
    }
    function handleChange(index, name, value) {
        let newMenu = menu;
        if (value === 'isSpecial') {
            newMenu[index].isSpecial = !menu[index].isSpecial;
        } else {
            newMenu[index][name] = value;
        }

        setMenu([...newMenu]);
    };
    function handleCheckAll(value, checked) {
        const nextValue = checked ? options : [];
        setLocationValues(nextValue);
        setIndeterminate(false);
        setCheckAll(checked)
    }
    function handleChangeLocation(value) {
        setLocationValues(value);
        setIndeterminate(value.length > 0 && value.length < options.length);
        setCheckAll(value.length === options.length);
    }
    function addMenu () {
        setLoading(true);
        let locations = {
            jvfg_cham: false,
            rsg_cham: false,
            kantine10: false,
            crown: false,
            gms: false
        };
        locationValues.forEach(item => locations[item] = true);
        let menuToSave = {
            menu_name: ' ',
            menu_name_en: ' ',
            menu_id: '',
            weekday: dayjs(menuDay).format('dddd'),
            date: dayjs(menuDay).format('YYYY-MM-DD'),
            calendar_week: dayjs(menuDay).week(),
            price: 0.0,
            is_special: 0,
            closed: 1,
            additions: '',
            rsg_cham: false,
            jvfg_cham: false,
            kantine10: false,
            crown: false,
            gms: false
        }

        if(locationValues.length <= 0) {
            Alert.error('Bitte wählen Sie mindestens eine Kantine aus!')
            setLoading(false)
        } else {

            if (!dayClosed) {
                menu.forEach((menuItem, index) => {
                    let additionsString = menuItem.additions.length > 0 && menuItem.additions.reduce((acc, cur) => {
                        return acc + '; ' + cur
                    })
                    menuToSave = {
                        menu_name: menuItem.menuName,
                        menu_name_en: menuItem.menuNameEn,
                        menu_id: 'menu' + menuItem.menu_id,
                        weekday: dayjs(menuDay).format('dddd'),
                        date: dayjs(menuDay).format('YYYY-MM-DD'),
                        calendar_week: dayjs(menuDay).week(),
                        price: parseFloat(menuItem.price.replace(',', '.')),
                        is_special: menuItem.isSpecial ? 1 : 0,
                        closed: 0,
                        additions: additionsString,
                    }

                    Object.assign(menuToSave, locations)

                    let token = localStorage.getItem('sh-token');
                    axios.post(API_HOST + '/menu', menuToSave, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                        .then(response => {
                            if (response.status < 300) {
                                Alert.success('Menü wurde gespeichert')
                                setMenu([{
                                    id: 1,
                                    menuName: '',
                                    menuNameEn: '',
                                    price: null,
                                    isSpecial: false,
                                    additions: []
                                }]);
                                setMenuDay(today);
                                setLocationValues([]);
                                setIndeterminate(false);
                                setCheckAll(false);
                                setLoading(false)
                            }
                        })
                        .catch(exp => {
                            Alert.error('Leider ist ein Fehler aufgetretten: ' + exp)
                            setLoading(false)
                        })
                })
            } else {
                let token = localStorage.getItem('sh-token');
                Object.assign(menuToSave, locations)

                axios.post(API_HOST + '/menu', menuToSave, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(response => {
                        if (response.status < 300) {
                            Alert.success('Menü wurde gespeichert')
                            setLoading(false)
                        }
                    })
                    .catch(exp => {
                        Alert.error('Leider ist ein Fehler aufgetretten: ' + exp)
                        setLoading(false)
                    })
            }
        }

    }
    if (loading) {
        return (
            <Loader backdrop content="loading..." vertical />
        )
    } else {

        return (
            <div className={'component-root'}>
                <h1>Neues Menü für {dayjs(menuDay).format('DD.MM.')} in der KW {dayjs(menuDay).week()} eintragen</h1>
                <Panel header={(<span><Icon icon={'plus-circle'}/> Neues Menü</span>)} bordered>
                    <FlexboxGrid justify={'space-between'}>
                        <FlexboxGrid.Item colspan={10}>
                            <DatePicker
                                size="lg"
                                placeholder="Tag wählen"
                                format={'DD.MM.YYYY'}
                                isoWeek
                                showWeekNumbers
                                oneTap
                                block
                                value={menuDay}
                                onChange={(date) => setMenuDay(date)}
                                locale={locale}
                                cleanable={false}
                            />

                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <Checkbox
                                indeterminate={indeterminate}
                                checked={checkAll}
                                onChange={handleCheckAll}
                            >Alle Auswählen</Checkbox>
                            <CheckboxGroup
                                inline
                                name={'checkLocations'}
                                value={locationValues}
                                onChange={handleChangeLocation}
                            >
                                <Checkbox value={'rsg_cham'}>Robert-Schumann-Gymnasium</Checkbox>
                                <Checkbox value={'jvfg_cham'}>Joseph-von-Fraunhofer-Gymnasium</Checkbox>
                                <Checkbox value={'kantine10'}>Kantine 10</Checkbox>
                                <Checkbox value={'crown'}>Kantine Crown</Checkbox>
                                <Checkbox value={'gms'}>GMS Roding</Checkbox>
                            </CheckboxGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}>
                            <Checkbox value={dayClosed} onChange={() => setDayClosed(!dayClosed)}>Geschlossen, Feiertag,
                                Urlaub</Checkbox>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>

                    <Divider/>
                    {menu.map((item, index) => {
                        return (
                            <MenuForm key={item.id + '_' + index} index={index} menu={item} onChange={handleChange}
                                      disabled={dayClosed} removeRow={removeRow}/>
                        )
                    })}
                    <Button style={{float: 'left'}} ripple onClick={() => addRow()}><Icon
                        icon={'plus'}/>
                        &nbsp;Menü hinzufügen
                    </Button>
                    <Button appearance="primary" color={'green'} style={{float: 'right'}} ripple
                            onClick={() => addMenu()}><Icon
                        icon={'user-plus'}/>
                        &nbsp;Menüs Speichern
                    </Button>
                    <div style={{clear: 'both'}}/>
                </Panel>
            </div>
        );
    }
}

export default AddMenu;